/*
  methods to use and persist state
  @NOTE because of limitations with JSON and localStorage, null is indistinguishable from undefined. If state is missing the default value is null, and setState(null) will unset the storage.
  */

import { useState, useMemo, useEffect, useCallback } from 'react'
import LocalStorage from '../storage'

type OnValueChange = (newValue: any) => void
let localListeners: Map<string, Set<OnValueChange>> = new Map()

function storageSubcription(key: string, onValueChange: OnValueChange) {
  // cross tab support via storage change events (localStorage only)

  !localListeners.get(key) && localListeners.set(key, new Set())

  const isSupported = typeof window !== 'undefined' && window.addEventListener
  if (!isSupported) {
    // we can probably remove this later, just a dev helper for now
    console.error('storage events are not supported in this environment')
    return
  }

  let listener = ({ key: k, newValue }: any) => {
    if (k === key) {
      onValueChange(newValue)
    }
  }
  localListeners.get(key)?.add(onValueChange)
  window.addEventListener('storage', listener)
  return () => {
    window.removeEventListener('storage', listener)
    localListeners.get(key)?.delete(onValueChange)
  }
}
export function storageObjectSubcription<S>(key: string, onValue: (newState: S | null) => void) {
  return storageSubcription(key, (state: string) => {
    onValue(state === null ? null : JSON.parse(state))
  })
}

let memoryCache: { [key: string]: string | null } = {}
export function usePersistedState<S extends string | null>(
  key: string,
  defaultValue: S
): [S, (state: S) => void, boolean] {
  let [state, setState] = useState<S>((memoryCache[key] as S) || defaultValue)
  let [pending, setPending] = useState(!memoryCache[key])

  useEffect(() => {
    return storageSubcription(key, setState)
  }, [key, setState])

  useEffect(() => {
    LocalStorage.getItem(key)
      .then(value => {
        value && setState(value as S) // we trust the value which is a string is actually the covariant type S
        setPending(false)
      })
      .catch(err => {
        console.error('error while persisting state, persisted state will be discarded', err)
        setPending(false)
      })
  }, [key])

  let setAndPersist = useCallback(
    (newState: S) => {
      setPersistedState(key, newState)
      // @TODO add validation that state can be stringified
      setState(newState)
      // @ts-ignore ts does not understand we already confirmed newState is not null
    },
    [key, setState]
  )
  return [state, setAndPersist, pending]
}

export function usePersistedObjectState<S extends object | null>(
  key: string,
  defaultValue: S
): [S, (state: S) => void, boolean] {
  let [serialState, setSerialState, pending] = usePersistedState<string | null>(key, null)

  let state = useMemo(() => {
    try {
      return serialState ? JSON.parse(serialState) : defaultValue
    } catch (err) {
      setSerialState(null)
      if (process.env.NODE_ENV !== 'production') console.error('failed to deserialize state', serialState, err)
      else return defaultValue
    }
  }, [serialState, setSerialState, defaultValue])

  let setAndPersist = useCallback(
    (newState: S) => {
      let newStateSerialized = newState === null ? null : JSON.stringify(newState)
      setSerialState(newStateSerialized)
    },
    [setSerialState]
  )
  return [state, setAndPersist, pending]
}

export function getPersistedState(key: string) {
  return LocalStorage.getItem(key).then(value => {
    memoryCache[key] = value
    return value
  })
}

export async function getPersistedObjectState(key: string) {
  return JSON.parse((await LocalStorage.getItem(key)) || 'null')
}

export function setPersistedState(key: string, newState: string | null) {
  memoryCache[key] = newState
  localListeners.get(key)?.forEach(l => l(newState))
  return newState !== null ? LocalStorage.setItem(key, newState) : LocalStorage.removeItem(key)
}

export async function setPersistedObjectState(key: string, newState: object | null) {
  return setPersistedState(key, newState === null ? null : JSON.stringify(newState))
}

export function clearPersistKey(persistKey: string) {
  localStorage.removeItem(persistKey)
}
